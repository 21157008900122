/**
 * Passando lo stato valuta i filtri relativamente a tutti gli eventi presenti nello stato e restituisce un nuovo oggetto contenente tutti gli eventi con il campo visible aggiornato
 * @param {dict} state stato dell'applicazione 
 * @returns dizionario degli eventi con il campo visible aggiornato
 */
export const checkAllEventoFiltri = (state: any): any => {
    var tempEventi = {...state.eventi}
    Object.keys(tempEventi).forEach(pevento_id => {
        tempEventi[pevento_id].visible = checkEventoFiltri(pevento_id, state)
    });
    return tempEventi
}



/**
 * Funzione che valuta se un evento deve essere visualizzato o meno in base ai filtri impostati presenti nello stesso stato  
 * @param {number} pevento_id identificativo dell'evento
 * @param {any} state stato dell'applicazione
 * @returns {boolean} true se l'evento deve essere visualizzato, false altrimenti
 */
export const checkEventoFiltri = (pevento_id: number, state: any): boolean =>{

    // *********************** - CAMPIONATO - ***********************
    // verifico se il campionato id dell'evento è presente o meno nell'array dei campionati non visualizzati
    // se presente non devo visualizzare l'evento altrimenti si
    let filtro_campionato_attivo = !state.campionati_non_visualizzati.includes(state.eventi[pevento_id].pcampionato_id)


    // *********************** - FILTRO EVENTO - ***********************
    let filtro_evento_attivo = false
    // verifico se la sottostringa è presente nel nome dell'evento
    if(state.filtro_evento != ''){
        filtro_evento_attivo = state.eventi[pevento_id].home.toLowerCase().includes(state.filtro_evento.toLowerCase()) || state.eventi[pevento_id].away.toLowerCase().includes(state.filtro_evento.toLowerCase()) 
    }else{
        filtro_evento_attivo = true
    }

    // *********************** - OCCHIO - ***********************
    // valutazione del filtro relativo all'occhio
    let alert_attivo = false
    if(state.eye_state){
        // se l'occhio è attivo devo verificare se è presente almeno una relative all'evento hanno un alert attivo
        if(pevento_id in state.quote){
            let quote = state.quote[pevento_id]
            // devo verificarmi se per ogni quota è presente almeno un alert
            Object.keys(quote).forEach(quota_id => {
                // devo verificare se la quota_id appartiene ad una specialità visibile
                if(isQuotaVisible(quota_id, state.specialita)){
                    Object.keys(quote[quota_id]).forEach(concessionario_id => {
                        // verifico che non è un concessionario di confronto e se il concessionario è visibile
                        if(!isConcessionarioConfronto(concessionario_id, state.alertConfronti) && isConcessionarioVisible(concessionario_id, state.concessionari)){
                            // se non è un concessionario di confronto verifico se è presente almeno un alert attivo
                            // per ogni concessionario di presente nell'alert vedo se quel confronto è abilitato
                            if(quote[quota_id][concessionario_id].alerts){
                                quote[quota_id][concessionario_id].alerts.forEach(element => {
                                    // basta che una quota abbia un alert attivo rispetto ad un concessionario abilitato per far si che l'evento sia visibile
                                    alert_attivo = alert_attivo || confrontoAbilitato(element.concessionario_id, state.alertConfronti)
                                    if (alert_attivo) return;
                                });
                            }
                        }               
                    });
                }
            });
        }
    }else{
        // non essendoci altri filtri da valutare l'evento è visibile
        alert_attivo = true
    }

    return alert_attivo && filtro_evento_attivo && filtro_campionato_attivo
}

/**
 * Permette di verificare se un concessionario_id è presente nell'array degli alertConfronti ed è abilitato
 * @param {int} concessionario_id identificativo del concessionario
 * @param {array} alertConfronti insieme dei confronti presenti
 * @returns {boolean} true se il confronto è abilitato, false altrimenti
 */
export const confrontoAbilitato = (concessionario_id: number, alertConfronti: any): boolean =>{
    var abilitato = false
    // verifico se il confronto è abilitato
    alertConfronti.forEach(element => {
        if(element.concessionario_id == concessionario_id){
            abilitato = element.abilitato
            return
        }
    });
    return abilitato
}

/**
 * Permette di verificare se un concessionario_id è presente nell'array degli alertConfronti
 * @param {int} concessionario_id identificativo del concessionario
 * @param {array} alertConfronti insieme dei confronti presenti
 * @returns {boolean} true se il concessionario è presente, false altrimenti
 */
export const isConcessionarioConfronto = (concessionario_id: number, alertConfronti: any): boolean =>{
    var confronto = false
    // verifico se il confronto è abilitato
    alertConfronti.forEach(element => {
        if(element.concessionario_id == concessionario_id){
            confronto = true
            return
        }
    });
    return confronto
}

/**
 * Verifico se il concessionario è visibile
 * @param {number} concessionario_id indeitificativo del concessionario
 * @param {array} concessionari lista di concessionari presenti 
 * @returns True se il concessionario è visibile, false altrimenti
 */
export const isConcessionarioVisible = (concessionario_id: number, concessionari: any): boolean =>{
    var visible = false
    // verifico se il confronto è abilitato
    concessionari.forEach(element => {
        if(element.concessionario_id == concessionario_id){
            visible = element.visible
            return
        }
    });
    return visible
}


/**
 * Verifico tramite la quota_id se la specialita a cui appartiene è visibiles
 * @param {number} quota_id identificativo della quota 
 * @param {array} specialita elenco delle specialità presenti
 * @returns {boolean} true se la specialità è visibile, false altrimenti
 */
export const isQuotaVisible = (quota_id: number, specialita: any): boolean =>{
    let visible = false
    let find = false // variabile utile per poter terminare la ricerca e uscire dal ciclo più esterno
    specialita.forEach(spec => {
        if (find) return
        // scorro gli esiti della specialità
        spec.esiti.forEach(esito => {
            // scorro le quote dell'esito
            if(esito.pquota_id == quota_id){
                visible = spec.visible
                find = true
                return
            }
        });
    })
    return visible
}


/**
 * Verifica se una quota_id è presente nella struttura delle specialità
 * @param {number} quota_id 
 * @param {array} specialita 
 * @returns ritorna True se la quota è presente in almeno una specialità, false altrimenti
 */
export const isQuotaPresente = (quota_id: number, specialita: any): boolean =>{
    let presente = false
    let find = false // variabile utile per poter terminare la ricerca e uscire dal ciclo più esterno
    specialita.forEach(spec => {
        if (find) return
        // scorro gli esiti della specialità
        spec.esiti.forEach(esito => {
            // scorro le quote dell'esito
            if(esito.pquota_id == quota_id){
                presente = true
                find = true
                return
            }
        });
    })
    return presente
}

