import { LiveConst } from "../../constants/live";
import { getAlertColor, getAlertColorText } from "../../utils/alert";
import { getConcessionarioColor } from "../../utils/utils_func";


const CellaQuote = (props) => {
    const evento = props.evento;
    const concessionari = props.concessionari;
    const specialita = props.specialita; // è la singola specialita non l'intero array delle specialita
    const quote = props.quote;
    const alertConfronti = props.alertConfronti;
    
    return(
        <div className="container text-center cellaquote">
            {concessionari && concessionari.map((concessionario, index) => 
            concessionario.visible &&
                <div key = {index + props}
                    className="row p-0" 
                    style={{height: LiveConst.ALTEZZA_RIGA_CONCESSIONARIO, userSelect: "none"}}
                >
                    {specialita && specialita.esiti.map((esito, indice) =>{

                        let checkAlert = ( 
                                quote 
                                && esito.pquota_id in quote 
                                && concessionario.concessionario_id in quote[esito.pquota_id] 
                                && 'alerts' in quote[esito.pquota_id][concessionario.concessionario_id]
                                && quote[esito.pquota_id][concessionario.concessionario_id]['alerts'].length > 0)
                        return (
                            <div 
                                key={indice + props}
                                className='p-1' 
                                // Se l'alert è presente aggiungo come propriteta il colore di sfondo che è presente nell'oggetto alertConfronti
                                style={{
                                    width: LiveConst.COLUMN_HEADER_WIDTH - (indice === (specialita.esiti.length - 1)? 1: 0), 
                                    fontSize:'12px', 
                                    backgroundColor: (checkAlert)? getAlertColor(quote[esito.pquota_id][concessionario.concessionario_id]['alerts']) : getConcessionarioColor(concessionario.concessionario_id, concessionari),
                                    fontWeight: (checkAlert)? 'bold' : 'normal',
                                    color: (checkAlert)? getAlertColorText(quote[esito.pquota_id][concessionario.concessionario_id]['alerts']) : '#000',
                                    }}
                            
                            >
                                {(quote && esito.pquota_id in quote && concessionario.concessionario_id in quote[esito.pquota_id] && 'value' in quote[esito.pquota_id][concessionario.concessionario_id] && quote[esito.pquota_id][concessionario.concessionario_id]['value'] > 1)
                                ? parseInt(quote[esito.pquota_id][concessionario.concessionario_id]['value']) < 999 ? parseFloat(quote[esito.pquota_id][concessionario.concessionario_id]['value']).toFixed(2) : quote[esito.pquota_id][concessionario.concessionario_id]['value']
                                : '-'}
                            </div>
                        )  
                    }
                    )}
                </div>
            )}
        </div>
    );
}
export default CellaQuote;