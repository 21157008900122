import React from 'react';  
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'  
import './index.css';
import App from './App';
import Live from './pages/Live';


const routing = (
    <Router>  
        <Routes>
            <Route path="/" element={<App/>} />
            <Route path="/live" element={<Live/>} />
        </Routes> 
    </Router>  
    )

export default routing;
