import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, OverlayTrigger, Row } from 'react-bootstrap';
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import * as LiveActions from '../../redux/live/actions';
import { GiLaurelsTrophy } from "react-icons/gi";


// Rappresenta il singolo alert visualizzato all'interno della barra a destra di Live.js
function Alert(Props) {

    // Colori usati nei vari stili
    const percMinBackground = "#495057";
    const intestazioneBackground = "#98d9dd";
    const concRifBackground = "#E4F0F9";
    const concNonRifBackground = "infoBackground";

    const percMinColor = "#FFFFFF";
    const timerColor = "#f1e6e6";
    const cestinoColor = "#f1e6e6";
    const concMercColor = "#0D47A1";
    const quotaColor = "#002761";
    const campionatoColor = "#221d1d";

    // stili inerenti alle info dell'evento
    const percentualeGuadagnoColStyle = {
        backgroundColor: percMinBackground
    }

    const percentualeGuadagnoStyle =
    {
        fontSize: "15px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: percMinColor
    }

    const eventoTitoloColStyle = {
        backgroundColor: intestazioneBackground,
        overflow: "hidden"
    }

    const eventoTitoloStyle =
    {
        fontSize: "14px",
        textWrap: "nowrap",
        overflow: "hidden",
        fontWeight: "700",
    }

    const dataInizioCampionatoStyle = {
        fontSize: "11px",
        overflow: "hidden",
        textWrap: "nowrap",
    }


    const timeStampAlertColStyle = {
        backgroundColor: percMinBackground
    }

    const timerStyle = {
        color: timerColor
    }

    const timeStampAlertStyle = {
        fontSize: "14px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: percMinColor,
    }

    const cestinoStyle = {
        cursor: "pointer",
        color: cestinoColor
    }

    const CampionatoColStyle = {
        backgroundColor: intestazioneBackground,
    }

    const campionatoStyle = {
        color: campionatoColor,
        cursor: "pointer"
    }

    // stili inerenti ai concessionari
    const concessionarioInfoStyle = {
        fontSize: "13px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: concMercColor
    }

    const concessionarioInfoQuotaDescrizioneStyle = {
        fontSize: "13px",
        textWrap: "nowrap",
        color: concMercColor
    }

    const concessionarioInfoEventoStyle = {
        fontSize: "13px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: "black"
    }

    const concessionarioQuotaStyle = {
        fontSize: "14px",
        textWrap: "nowrap",
        overflow: "hidden",
        fontWeight: "900",
        color: quotaColor
    }


    // stili inerenti ai risultati
    const risultatiColStyle =
    {
        backgroundColor: "green",
    }

    const risultatiStyle =
    {
        fontSize: "13px",
        color: "white"
    }

    // Stili inerenti al minuto/set/basket

    const minutiColStyle = {
        backgroundColor: "black",
        borderLeft: "1px solid #999",
        padding: "0 7px 0 7px"
    }

    const minutiStyle = {
        fontSize: "14px",
        color: "white"
    }

    const setBasColStyle = {
        backgroundColor: "black"
    }

    const setBasDivStyle =
    {
        borderLeft: "1px solid #999",
        padding: "0 7px 0 7px"
    }

    const setBasStyle = {
        fontSize: "13px",
        color: "white"
    }


    const dispatch = useDispatch();

    // Formattazione della data dell'evento generale
    const data = new Date(Props.eventi[Props.alertInfo.alert.pevento_id].start);
    let dataInizio = `${data.getDate().toString().padStart(2, '0')}/${(data.getMonth() + 1).toString().padStart(2, '0')} ${data.getHours().toString().padStart(2, '0')}:${data.getMinutes().toString().padStart(2, '0')}`

    // Recupero del nome dell'evento generale
    let eventoGenerale = `${Props.eventi[Props.alertInfo.alert.pevento_id].home} - ${Props.eventi[Props.alertInfo.alert.pevento_id].away}`;

    // Formattazione del timestamp generale in secondi, minuti e ore 
    function timeStampParse() {
        let timeStampDiff = Date.now() - Props.alertInfo.alert.timestamp;
        let secondi = Math.floor(timeStampDiff / 1000);
        let minuti = Math.floor(secondi / 60);
        let ore = Math.floor(minuti / 60);

        if (ore > 0) {
            return `${ore} ore`;
        } else if (minuti > 0) {
            return `${minuti} min`;
        } else {
            return `${secondi} sec`;
        }
    }

    // Recupero nome dell'evento rinominato dal singolo concessionario
    function associazioneEvento(concessionario) {
        for (const assocKey in Props.elencoAssociazioniEventi) {
            const associazione = Props.elencoAssociazioniEventi[assocKey];
            if (associazione.pevento_id == Props.alertInfo.alert.pevento_id && associazione.concessionario_id == concessionario.concessionario_id) {
                return associazione.descrizione;
            }
        }
        return '';
    }

    // Split del primo nome evento se troppo lungo
    function trimEvento(eventoDescrizione) {
        let descrizioneSplit = eventoDescrizione.split(" - ");
        if (descrizioneSplit.length >= 2) {
            let squadraHome = descrizioneSplit[0];
            let squadraAway = descrizioneSplit[1];
            if (squadraHome.length > 15) {
                squadraHome = squadraHome.substring(0, 15);
            }
            if (squadraAway.length > 15) {
                squadraAway = squadraAway.substring(0, 15);
            }
            return `${squadraHome} - ${squadraAway}`;
        }
        return eventoDescrizione;
    }


    // Recupero descrizione quota e specialità del singolo concessionario
    function associazioneSpecialita(concessionario) {
        let quotaDescSpec = {};
        quotaDescSpec.quotaDescrizione = '';
        quotaDescSpec.specialita = '';
        for (const spec of Props.specialita) {
            for (const esito of spec.esiti) {
                if (esito.pquota_id == concessionario.pquota_id) {
                    quotaDescSpec.quotaDescrizione = esito.descrizione;
                    quotaDescSpec.specialita = spec.descrizione;
                    break;
                }
            }

            if (quotaDescSpec.specialita != '') {
                break;
            }
        }

        return quotaDescSpec;
    }


    // Gestione della visualizzazione del punteggio in base allo sport
    function punteggioBySport() {
        if (Props.psport_id == 1) {
            return (<div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.home_goals}
                </div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.away_goals}
                </div>
            </div>)
        } else if (Props.psport_id == 2) {
            return (<div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.currentGame.home}
                </div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.currentGame.away}
                </div>
            </div>)
        } else if (Props.psport_id == 3) {
            return (<div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.home_points}
                </div>
                <div style={risultatiStyle}>
                    {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.away_points}
                </div>
            </div>)
        }
    }

    // Gestione della visualizzazione dei minuti in base allo sport
    function minutiSetBasBySport() {
        if (Props.psport_id == 1) {
            return (
                <Col className='col-auto d-flex align-items-center' style={minutiColStyle}>
                    <div style={minutiStyle}>'{Props.eventi[Props.alertInfo.alert.pevento_id].info_live.minuto}</div>
                </Col>
            );
        } else if (Props.psport_id == 2) {
            return (
                <Col className='col-auto p-0' style={setBasColStyle}>
                    <div className='d-flex align-items-center'>
                        {Props.eventi[Props.alertInfo.alert.pevento_id].info_live.totalSets.map((set) => {
                            return (
                                <div style={setBasDivStyle}>
                                    <div style={setBasStyle}>{set.home}</div>
                                    <div style={setBasStyle}>{set.away}</div>
                                </div>
                            );
                        })}
                    </div>
                </Col>
            );
        }
        else if (Props.psport_id == 3) {
            return (
                <Col className='col-auto p-0 text-center' style={setBasColStyle}>
                    <div style={setBasDivStyle}>
                        <div style={setBasStyle}>Q{Props.eventi[Props.alertInfo.alert.pevento_id].info_live.quarto}</div>
                        <div style={setBasStyle}>'{Props.eventi[Props.alertInfo.alert.pevento_id].info_live.minuto}</div>
                    </div>
                </Col>
            );
        }
    }

    return (
        <Container style={{
            maxWidth: "600px",
        }}>
            {/*Recupero delle informazioni generali collegate all'evento*/}
            <Row className='border-bottom border-top border-dark'>
                <Col className='col-2 d-flex justify-content-center align-items-center' style={percentualeGuadagnoColStyle}>
                    <div style={percentualeGuadagnoStyle}>{Props.alertInfo.alert.percentuale_guadagno >= 100 ? Props.alertInfo.alert.percentuale_guadagno.toFixed(0) : Props.alertInfo.alert.percentuale_guadagno.toFixed(2)}%</div>
                </Col>
                <Col className='col-8 d-flex flex-row ps-0 pe-0' style={{ backgroundColor: intestazioneBackground }}>
                    <Col className="ps-1" style={eventoTitoloColStyle}>
                        <div style={eventoTitoloStyle}>{eventoGenerale}</div>
                        <div style={dataInizioCampionatoStyle}>{Props.eventi[Props.alertInfo.alert.pevento_id].descrizione}</div>
                    </Col>
                    { 
                        // Generazione della colonna minuti/set/basket in base allo sport
                        minutiSetBasBySport()
                    }
                    <Col className='col-1 text-center' style={risultatiColStyle}>
                        {// recupero del punteggio in base allo sport
                            punteggioBySport()}
                    </Col>
                </Col>
                <Col className='col-2 d-flex justify-content-end align-items-center' style={timeStampAlertColStyle}>
                    <Row>
                        <Col className='pe-0'>
                            <div style={timeStampAlertStyle}>{timeStampParse()}</div>
                        </Col>
                        <Col className='d-flex align-items-center pe-0'>
                            <AiOutlineDelete size={16} style={cestinoStyle} onClick={() => dispatch(LiveActions.removeAlertBar(Props.alertInfo.id))} />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*Recupero delle informazioni del singolo concessionario*/
                Props.alertInfo.alert.quote.map((concessionario, indice) => {
                    let associazioneEventoDesc = associazioneEvento(concessionario);
                    let quotaDescSpec = associazioneSpecialita(concessionario);
                    return (<Row className='pt-2 pb-2' style={{
                        backgroundColor: (indice == 0) ? concRifBackground : concNonRifBackground,
                    }}>
                        <Col className='col-2 pe-0'>
                            <div style={concessionarioInfoStyle}>{Props.concessionari.find((concessionarioCheck) => (concessionarioCheck.concessionario_id == concessionario.concessionario_id)).descrizione}</div>
                        </Col>
                        <Col className='col-5 pe-0'>
                            <div style={concessionarioInfoEventoStyle}><a title={associazioneEventoDesc}>{trimEvento(associazioneEventoDesc)}</a></div>
                        </Col>
                        <Col className='col-3 d-flex justify-content-center align-items-center'>
                            <div style={concessionarioInfoStyle}><a title={quotaDescSpec.specialita}>{quotaDescSpec.specialita}</a></div>
                        </Col>
                        <Col className='col-1 d-flex justify-content-start align-items-center ps-0'>
                            <div style={concessionarioInfoQuotaDescrizioneStyle}><a title={quotaDescSpec.quotaDescrizione}>{quotaDescSpec.quotaDescrizione}</a></div>
                        </Col>
                        <Col className='col-1 d-flex justify-content-center align-items-center p-0'>
                            <div style={concessionarioQuotaStyle}>{Number(Props.quote[Props.alertInfo.alert.pevento_id][concessionario.pquota_id][concessionario.concessionario_id]['value']).toFixed(2)}</div>
                        </Col>
                    </Row>)
                })}
        </Container>
    )
}
export default Alert;