/**
 * Verifica gli alert per una quota di un determinato concessionario
 * @param {int} concessionario_id concessionario di cui verificare l'alert
 * @param {array} alertConfronti array di alert da verificare
 * @param {dict} quoteEsito dizionario contenente tutte le quote relative all'esito
 * @returns false se non è da segnalare, altrimenti un array degli alert di alertConfronti che sono stati soddisfatti
 */
export const checkAlertsQuota = (concessionario_id, alertConfronti, quoteEsito) => {
    let alerts = [];
    // cerco il concessionario_id all'interno dell'array degli alert in modo da evitare confronti tra concessionari di confronto
    let isConcessionarioConfronto = alertConfronti.find(concessionarioConfronto => concessionarioConfronto.concessionario_id === parseInt(concessionario_id));
    if (!isConcessionarioConfronto) {
        // scorro l'array degli alert
        alertConfronti.forEach(concessionarioConfronto => {
            // verifico in primis se l'alert è abilitato
            if (concessionarioConfronto.abilitato) {
                // verifico se la quota che sto confrontando è propri relativa a un concessionario di confronto
                if (concessionarioConfronto.concessionario_id !== parseInt(concessionario_id) && concessionarioConfronto.concessionario_id in quoteEsito) {
                    if (parseFloat(quoteEsito[concessionario_id]['value']) > 1 && parseFloat(quoteEsito[concessionarioConfronto.concessionario_id]['value']) > 1) {
                        // se la quota è maggiore o uguale all'alert allora aggiungo l'alert con il colore di default
                        if ((parseFloat(quoteEsito[concessionario_id]['value']) + parseFloat(concessionarioConfronto.distanza)) >= parseFloat(quoteEsito[concessionarioConfronto.concessionario_id]['value'])) {
                            if (parseFloat(quoteEsito[concessionario_id]['value']) >= parseFloat(quoteEsito[concessionarioConfronto.concessionario_id]['value'])) {
                                alerts.push(concessionarioConfronto);
                            } else {
                                // se la quota, sommata con la distanza del concessionario di confronto, è maggiore o uguale all'alert allora aggiungo l'alert ma inserendo un colore differente
                                // da quello di default presente nell'attributo colore_con_distanza, colore_testo_con_distanza 
                                let concessionarioConfrontoWithDistance = { ...concessionarioConfronto };
                                concessionarioConfrontoWithDistance.colore = concessionarioConfronto.colore_con_distanza;
                                concessionarioConfrontoWithDistance.colore_testo = concessionarioConfronto.colore_testo_con_distanza;
                                alerts.push(concessionarioConfrontoWithDistance);
                            }
                        }
                    }


                }
            }
        });
    }
    return alerts.length > 0 ? alerts : false;
}


/**
 * Permette di ottenere il colore dell'alert con priorità più alta all'interno della lista
 * @param {array} alerts array degli alert che contengono le informazioni sulla priorità e il colore
 * @returns {string} il codice del colore da applicare alla cella 
 */
export const getAlertColor = (alerts) => {
    let colore = '';
    let priority = false;
    // scorro l'array degli alert
    alerts.forEach(alert => {
        if (alert.priority < priority || !priority) {
            colore = alert.colore;
            priority = alert.priority;
        }
    });

    return colore;
}


/**
 * Permette di ottenere il colore del testo dell'alert con priorità più alta all'interno della lista
 * @param {array} alerts array degli alert che contengono le informazioni sulla priorità e il colore
 * @returns {string} il codice del colore da applicare alla cella 
 */
export const getAlertColorText = (alerts) => {
    let colore_testo = '';
    let priority = false;
    // scorro l'array degli alert
    alerts.forEach(alert => {
        if (alert.priority < priority || !priority) {
            colore_testo = alert.colore_testo;
            priority = alert.priority;
        }
    });

    return colore_testo;
}


// TODO: definire le funzioni che verificano gli alert e li settano nelle quote, seguendo la struttura degli eventi_id | quota_id
/**
 * Set degli alert per tutte le quote presenti all'interno della struttura
 * @param {dict} quote passaggio per riferimento dell'intera struttura dati delle quote 
 * @param {array} alertConfronti array di alert da verificare
 * @param {dict} eventi passaggio per riferimento dell'intera struttura dati degli eventi
 */
export const setAlerts = (quote, alertConfronti, eventi) => {
    Object.keys(quote).forEach(evento_id => {
        // verifico se l'evento_id è presente all'interno della struttura degli eventi
        let evento = null
        if (evento_id in eventi) {
            evento = eventi[evento_id];
        }
        // passo l'evento anche alla funzione setAlertsEvento per verificare gli alert relativi alle quote
        setAlertsEvento(quote[evento_id], alertConfronti, evento);
    })
};


/**
 * Set degli alert per le quote relative ad un evento
 * @param {dict} quoteEvento quote relative ad un evento
 * @param {array} alertConfronti  array di alert da verificare
 * @param {dict} evento passaggio per riferimento dell'intera struttura dati dell'evento
 */
export const setAlertsEvento = (quoteEvento, alertConfronti, evento) => {
    Object.keys(quoteEvento).forEach(quota_id => {
        setAlertsEsito(quoteEvento[quota_id], alertConfronti, evento);
    })
}



/**
 * Set degli alert per le quote relative ad un esito
 * @param {dict} quoteEsito  quote relative ad un esito
 * @param {array} alertConfronti  array di alert da verificare
 */
export const setAlertsEsito = (quoteEsito, alertConfronti, evento) => {
    Object.keys(quoteEsito).forEach(concessionario_id => {
        let oldAlerts = quoteEsito[concessionario_id]['alerts'];
        let alerts = checkAlertsQuota(concessionario_id, alertConfronti, quoteEsito);
        quoteEsito[concessionario_id]['alerts'] = alerts;
        // aggiorno il numero di alert relativi alla quota non distinguendo i tipi di alert
        // ad esempio se su una quota avrò sia un Alert di BF e uno di PINNACLE per il programma sarà conteggiato come singolo alert per quel concessionario
        // l'evento potrebbe essere null se non è presente nella struttura
        if (evento) {
            if (alerts && !oldAlerts) {
                if (evento.count_quota_alert == 0) {
                    // attivo la data di attivazione dell'alert
                    evento.data_attivazione_alert = new Date();
                }
                evento.count_quota_alert += 1;
            } else if (!alerts && oldAlerts && evento.count_quota_alert > 0) {
                evento.count_quota_alert -= 1;
                if (evento.count_quota_alert == 0) {
                    // disattivo la data di attivazione dell'alert
                    evento.data_attivazione_alert = null;
                }
            }
        }
    });
}


