import '../css/live.css';
import React, { useState, useEffect } from 'react';
import { Grid, AutoSizer, ScrollSync } from 'react-virtualized';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as LiveActions from '../redux/live/actions';
import { useLocation } from "react-router-dom";
import { LiveConst } from "../constants/live";
import CellaEvento from '../components/live/CellaEvento';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CellaIntestazioneSpecialita from '../components/live/CellaIntestazioneSpecialita';
import CellaQuote from '../components/live/CellaQuote';
import { FaEye, FaGlobeAmericas, FaRegSave, FaRegBell, FaRegBellSlash, FaFontAwesome, FaHamburger } from "react-icons/fa";
import { BiMath } from "react-icons/bi";
import { TfiCup } from "react-icons/tfi";
import { FaList, FaDeleteLeft, FaBurger } from "react-icons/fa6";
import { IoReload } from "react-icons/io5";
import { Modal, FormCheck, FormControl, FormGroup, Row, InputGroup, Container, Button, Col } from 'react-bootstrap';
import { MdOutlineWifi, MdOutlineWifiOff } from "react-icons/md";
import { generateListaCampionati, reorderEventi } from '../utils/utils_func';
import { isQuotaPresente } from '../utils/filtri';
import { IoIosWarning } from "react-icons/io";
import Alert from '../components/AlertsBar/Alert';
import { IoSettingsOutline } from "react-icons/io5";
import Form from 'react-bootstrap/Form';



const Live = () => {
    const dispatch = useDispatch();
    window.impostaDati = impostaDati  // permette di utilizzare questa funzione da riga console  
    const alertsBar = useSelector(state => state.Live.alertsBar); // recupero lo stato globale degli alert nella barra a destra
    const sortAlertBarInfo = useSelector(state => state.Live.sortAlertBarInfo); // recupero le informazioni inerenti al sorting degli alert
    const specialita = useSelector(state => state.Live.specialita);
    const concessionari = useSelector(state => state.Live.concessionari);
    const eventi = useSelector(state => state.Live.eventi);
    const elencoAssociazioniEventi = useSelector(state => state.Live.elencoAssociazioniEventi);
    const quote = useSelector(state => state.Live.quote);
    const location = useLocation();
    const alertConfronti = useSelector(state => state.Live.alertConfronti);
    const params = new URLSearchParams(location.search)
    const [numEventi, setNumEventi] = useState(Object.keys(getEventiVisibili()).length);
    const eventi_ignorati = useSelector(state => state.Live.eventi_ignorati);
    const eye_state = useSelector(state => state.Live.eye_state);
    // stato locale che contiene l'elenco dei campionati
    const [listaCampionati, setListaCampionati] = useState({});
    const campionati_non_visualizzati = useSelector(state => state.Live.campionati_non_visualizzati);
    const [search_evento_string, setSearchEventoString] = useState('');
    // definire lo stato per l'ultimo aggiornamento della pagina di defauly il tempo attuale
    const [ultimo_aggiornamento, setUltimoAggiornamento] = useState(new Date().toLocaleTimeString("it-IT", { timeZone: "Europe/Rome" }));
    const alert_sound = useSelector(state => state.Live.alert_sound);
    const psport_id = useSelector(state => state.Live.psport_id);
    // valore di default per l'ultima volta che sono state aggiornate le associazioni: di default al timestamp attuale dato che le carico all'avvio
    const [last_update_associazioni, setLastUpdateAssociazioni] = useState(new Date().getTime());
    const [socketConnected, setSocketConnected] = useState(false);
    window.setSocketConnected = setSocketConnected  // permette di utilizzare questa funzione da riga console

    // Gestione del sorting degli alert nella barra a destra
    function sortAlertBar(info) {
        if (info.name == "sortType") {
            dispatch(LiveActions.sortAlertBarInfo(Number(info.value), sortAlertBarInfo.order));
        } else if (info.name == "sortOrder") {
            dispatch(LiveActions.sortAlertBarInfo(sortAlertBarInfo.sorting, Number(info.value)));
        }
    }

    /**
     * Tramite lo stato globale "eventi" ritorna un sottoinsieme di eventi che sono visibili che possono essere visualizzati
     * @returns {dict} sottoinsieme degli eventi visibili
     */
    function getEventiVisibili() {
        let eventiVisibili = {}
        Object.keys(eventi).forEach(pevento_id => {
            if (eventi[pevento_id].visible)
                eventiVisibili[pevento_id] = eventi[pevento_id]
        });
        return eventiVisibili
    }


    /**
     * Tramite lo stato globale "specialita" ritorna un sottoinsieme di specialita che sono visibili che possono essere visualizzati
     * @returns {array} sottoinsieme delle specialita visibili
    */
    function getSpecialitaVisibili() {
        let specialitaVisibili = []
        specialita.forEach(spec => {
            if (spec.visible)
                specialitaVisibili.push(spec)
        }
        );
        return specialitaVisibili
    }

    /**
     * Traduce lo stato globale "concessionari" ritorna un sottoinsieme di concessionari che sono visibili
     * @returns {dizionario} sottoinsieme dei concessionari visibili
     */
    function getConcessionariVisisbili() {
        let concessionariVisibili = {}
        concessionari.forEach(concessionario => {
            if (concessionario.visible)
                concessionariVisibili[concessionario.concessionario_id] = concessionario
        });
        return concessionariVisibili
    }


    // funzione chiamata da console che permette di inserire i dati relativi alle quote o agli eventi
    function impostaDati(message) {
        let jsonMessage = JSON.parse(message)
        let tipo = jsonMessage['tipo']
        let jsonDati = jsonMessage['response']
        if (tipo == LiveConst.AGGIORNAMENTO_EVENTO) {
            // Recupero tutte le informazioni a prescindere se l'evento è già presente o meno
            let sid = parseInt(jsonDati.psport_id)
            // solo se l'evento appartiene allo sport_id allora lo aggiungo
            if (psport_id === sid) {
                let pevento_id = parseInt(jsonDati.pevento_id)
                // che il risultato sia una stringa divisa da un '-'
                let info_live = jsonDati.info_live
                let stato = jsonDati.stato
                if (pevento_id in eventi) {
                    if (stato === 'terminato') {
                        // cancello l'evento
                        dispatch(LiveActions.deleteEvento(pevento_id))
                    } else {
                        // setto le informazioni dell'evento
                        dispatch(LiveActions.setEventoDati(pevento_id, info_live, stato))
                    }
                } else {
                    if (!eventi_ignorati.includes(pevento_id)) {
                        // carica evento se non è presente nel dizionario degli eventi
                        // lo carico e gli passo anche le informazioni relative al risultato
                        dispatch(LiveActions.getEvento(pevento_id, info_live, stato))
                        // carica le quote dell'evento

                        // per evitare di richiedere due volte le stesse quote dato che lo stato di loading è true fino a quando non c'è ancora un evento
                        // se nella registrazione non avrò nessun evento lo stato di loading rimarrà true e quindi non caricherò le quote 
                        if (Object.keys(eventi).length > 0)
                            dispatch(LiveActions.getQuoteEvento(pevento_id))
                    }
                }
            }
        } else if (tipo == LiveConst.AGGIORNAMENTO_QUOTE) {
            let quoteAggiornate = []
            // variabile che mi indica se caricare o meno l'elenco delle associazioni
            let carica_associazioni = false
            let quote_sport_presente = [] // lista che contiene le quote che appartengono allo sport_id 
            jsonDati.forEach(quota => {
                // verifico se la quota appartiene alle specialità che sono presenti nell'applicazione
                // se non è presente non la aggiungo
                if (isQuotaPresente(quota.pquota_id, specialita)) {
                    // a prescindere dal fatto che sia presente o meno l'associazione la quota la aggiungo
                    // questo array verrà utilizzato SOLO se si dovranno caricare le associazioni
                    quote_sport_presente.push(quota)
                    // creo la chiave per l'elenco delle associazioni
                    let key_associazione = String(quota.evento_codice) + '-' + String(quota.concessionario_id)
                    // aggiungo all'elenco delle quote aggiornate solo quelle relative ad eventi che sono presenti nell'elenco delle associazioni
                    // in caso contrario setto la variabile carica_associazioni a true in modo tale che caricherò una singola volta l'elenco delle associazioni
                    if (key_associazione in elencoAssociazioniEventi) {
                        quoteAggiornate.push({
                            pevento_id: elencoAssociazioniEventi[key_associazione]['pevento_id'],
                            evento_codice: quota.evento_codice,
                            pquota_id: quota.pquota_id,
                            concessionario_id: quota.concessionario_id,
                            value: quota.valore,
                        })
                    } else if (!carica_associazioni) {
                        carica_associazioni = true
                    }
                }
            });
            if (carica_associazioni && (last_update_associazioni + LiveConst.EXPIRE_ULTIMO_AGGIORNAMENTO_ASSOCIAZIONI * 1000 < new Date().getTime())) {
                // quando carico l'elenco delle associazioni passerò solo le quote che appartengono allo sport_id evitando di provare ad inserire quote che non potranno mai essere visualizzate
                // poichè appartenenti ad uno sport differente da quello visualizzato
                setLastUpdateAssociazioni(new Date().getTime())
                dispatch(LiveActions.getElencoAssociazioniEventi(quote_sport_presente));
            } else if (quoteAggiornate.length > 0) {
                // aggiorno le quote
                dispatch(LiveActions.setQuoteFromSocket(quoteAggiornate))
            }
            setUltimoAggiornamento(new Date().toLocaleTimeString("it-IT", { timeZone: "Europe/Rome" }))
        } else if (tipo == LiveConst.LOAD_EVENTI) {
            setSocketConnected(true)
            // recupero l'elenco degli eventi che sono dei dizionari 
            if ('events' in jsonDati && jsonDati['events'].length > 0) {
                dispatch(LiveActions.getEventi(jsonDati['events']));
                // per ogni pevento_id presente nell'elenco, carico le quote
                jsonDati['events'].forEach(element => {
                    dispatch(LiveActions.getQuoteEvento(element['pevento_id']))
                });
            }
        }
    }



    useEffect(() => {
        // recupero i parametri dalla url
        let parameters = {}
        parameters['token'] = params.get('tok')
        parameters['psport_id'] = params.get('sid')
        dispatch(LiveActions.setParameters(parameters));
        dispatch(LiveActions.getSpecialita());
        dispatch(LiveActions.getConcessionari());
        // dato che devo passare anche l'elenco delle quote, invio un array vuoto
        dispatch(LiveActions.getElencoAssociazioniEventi([]));
        dispatch(LiveActions.getInversioniQuote())
    }, []);

    useEffect(() => {
        setColumnCount(getSpecialitaVisibili().length + 1);
        if (Object.keys(getConcessionariVisisbili()).length > 1) {
            setRowHeight(LiveConst.ALTEZZA_RIGA_CONCESSIONARIO * Object.keys(getConcessionariVisisbili()).length + 1);
        } // 30 poichè 25 è l'altezza della riga e 5 è la dimensione del bordo
    }, [specialita, concessionari]);


    useEffect(() => {
        setRowcount(Object.keys(getEventiVisibili()).length);
        setNumEventi(Object.keys(getEventiVisibili()).length);
    }, [Object.keys(eventi)])


    window.addEventListener("resize", () => {
        // una volta che la finestra è stata ridimensionata, non richiamo più la funzione per 10 secondi
        // in modo tale da evitare di chiamare la funzione più volte
        // se non ci fosse questo controllo, la funzione verrebbe richiamata più volte
        // e quindi il ridimensionamento della tabella non sarebbe fluido
        // se la finestra viene ridimensionata prima di 500 milli secondi, la funzione verrà richiamata nuovamente
        // e quindi il timeout verrà resettato
        clearTimeout(window.resizedFinished);
        window.resizedFinished = setTimeout(function () {
            setHeight(window.innerHeight - 110);
        }, 500);


    });


    function impostaQuoteEventi() {
        // posso fare solo il map sulle chiavi poichè è un dizionario dove le chiavi sono proprio i pevento_id inseriti come stringhe
        Object.keys(eventi).map((pevento_id) => {
            dispatch(LiveActions.getQuoteEvento(pevento_id))
        })
    }


    // definisco gli stati per la tabella
    const [columnWidth, setColumnwidth] = useState(LiveConst.COLUMN_WIDTH);
    const [rowHeight, setRowHeight] = useState(LiveConst.ROW_HEIGHT);
    const [rowHeaderHeight, setRowHeaderHeight] = useState(LiveConst.ROW_HEADER_HEIGHT);
    const [columnHeaderWidth, setColumnHeaderwidth] = useState(LiveConst.COLUMN_HEADER_WIDTH);
    const [columnCount, setColumnCount] = useState(getSpecialitaVisibili().length + 1);
    const [rowCount, setRowcount] = useState(Object.keys(getEventiVisibili()).length);
    const [height, setHeight] = useState(window.innerHeight - 110);
    const [overscanColumnCount, setOverscanColumnCount] = useState(0);
    const [overscanRowCount, setOverscanRowCount] = useState(5);

    // definisco gli stati per la popup dei concessionari
    const [openPopupConcessionari, setOpenPopupConcessionari] = useState(false);
    const [openPopupConfronti, setOpenPopupConfronti] = useState(false);
    const [openPopupSpecialita, setOpenPopupSpecialita] = useState(false);
    const [openPopupCampionati, setOpenPopupCampionati] = useState(false);

    // stato per la popup delle impostazioni inerenti all'alert bar
    const [openPopupAlertBarImpostazioni, setOpenPopupAlertBarImpostazioni] = useState(false);

    // stato per capire se mostrare la barra degli alert a destra
    const [rightMenuShow, setRighMenuShow] = useState(false);

    return (
        <>
            {/*IMPOSTAZIONI ALERT BAR*/}
            <Modal
                show={openPopupAlertBarImpostazioni}
                onHide={() => setOpenPopupAlertBarImpostazioni(!openPopupAlertBarImpostazioni)}
                size='sm'
                scrollable='true'>
                <Modal.Header onHide={() => setOpenPopupAlertBarImpostazioni(!openPopupAlertBarImpostazioni)} closeButton>
                    <h5 className="modal-title">Alert Bar</h5>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontSize: '13px', textAlign: "justify", paddingBottom: "16px" }}>
                        Configurare le impostazioni inerenti all'alert bar.
                    </div>
                    <p style={{ width: "100%", height: "2px", background: "#dee2e6", position: "absolute", left: 0 }}></p>
                    <div style={{ paddingTop: "16px" }}>
                        <FormGroup>
                            <Form.Label>Ordina per:</Form.Label>
                            <Row>
                                <Col className='col-6'>
                                    <Form.Select name='sortType' className='pt-0 pb-0' style={{
                                        height: "30px",
                                        fontSize: "13px",
                                    }} onChange={(e) => sortAlertBar(e.currentTarget)} defaultValue={sortAlertBarInfo.sorting}>
                                        <option value="0">Guadagno</option>
                                        <option value="1">Tempo</option>
                                    </Form.Select>
                                </Col>
                                <Col className='col-6 ps-0'>
                                    <Form.Select name='sortOrder' className='pt-0 pb-0' style={{
                                        height: "30px",
                                        fontSize: "13px",
                                    }} onChange={(e) => sortAlertBar(e.currentTarget)} defaultValue={sortAlertBarInfo.order}>
                                        <option value="2">Crescente</option>
                                        <option value="3">Decrescente</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </FormGroup>
                    </div>
                </Modal.Body>
            </Modal>
            {/* CONCESSIONARI */}
            <Modal
                show={openPopupConcessionari}
                onHide={() => setOpenPopupConcessionari(!openPopupConcessionari)}
                size='sm'
                scrollable='true'>
                <Modal.Header onHide={() => setOpenPopupConcessionari(!openPopupConcessionari)} closeButton>
                    <h5 className="modal-title">Concessionari</h5>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ fontSize: '13px', textAlign: "justify", paddingBottom: "16px" }}>
                        Selezionare il concessionario da visualizzare. Utilizzare la casella per impostare il colore della riga corrispondente
                    </div>
                    <p style={{ width: "100%", height: "2px", background: "#dee2e6", position: "absolute", left: 0 }}></p>
                    <div style={{ paddingTop: "16px" }}>
                        {concessionari.map((concessionario, index) => {
                            return (
                                <div key={index} className='popup-checkbox'>
                                    <FormGroup as={Row} className='mx-1 my-1'>
                                        <FormCheck
                                            style={{ width: '85%' }}
                                            checked={concessionario.visible}
                                            label={concessionario.descrizione}
                                            onChange={() => {
                                                dispatch(LiveActions.setConcessionarioVisible(concessionario.concessionario_id, !concessionario.visible))
                                                dispatch(LiveActions.checkFilters())
                                            }} />
                                        <FormControl
                                            type="color"
                                            size='sm'
                                            style={{ width: '15%' }}
                                            onChange={(e) => {
                                                // setto il colore alla riga del concessionario
                                                dispatch(LiveActions.setColoreConcessionario(concessionario.concessionario_id, e.target.value))
                                            }}
                                            value={concessionario.color}
                                        />
                                    </FormGroup>
                                </div>
                            )
                        })

                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* CONFRONTI */}
            <Modal
                show={openPopupConfronti}
                onHide={() => setOpenPopupConfronti(!openPopupConfronti)}
                size='sm'
                scrollable='true'>
                <Modal.Header onHide={() => setOpenPopupConfronti(!openPopupConfronti)} closeButton>
                    <h5 className="modal-title">Confronti</h5>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ fontSize: '13px', textAlign: "justify", paddingBottom: "16px" }}>
                        Selezionare il concessionario di confronto da abilitare. Il valore impostabile dalla casella sarà sommato
                        alle quote dei concessionari e attiverà un avviso se il risultato sarà maggiore
                        delle quote del concessionario di confronto.
                    </div>
                    <p style={{ width: "100%", height: "2px", background: "#dee2e6", position: "absolute", left: 0 }}></p>
                    <div style={{ paddingTop: "16px" }}>
                        {alertConfronti.map((item, index) => {
                            return (
                                (concessionari.find(concessionario => concessionario.concessionario_id == item.concessionario_id)) ?
                                    <div key={index} className='popup-checkbox'>
                                        <FormGroup as={Row} className="mx-1">
                                            <FormCheck
                                                checked={item.abilitato}
                                                label={item.descrizione}
                                                onChange={() => {
                                                    dispatch(LiveActions.changeAbilitazioneCofronto(item.concessionario_id, !item.abilitato))
                                                    dispatch(LiveActions.checkAlerts())
                                                    dispatch(LiveActions.checkFilters())
                                                }}
                                                style={{ width: '60%' }}
                                            />
                                            <FormControl
                                                type="number"
                                                id="inputDistance"
                                                size='sm'
                                                step={0.01}
                                                max={1}
                                                min={-1}
                                                disabled={!item.abilitato}
                                                onChange={(e) => {
                                                    dispatch(LiveActions.setDistanceConfronto(item.concessionario_id, e.target.value))
                                                    dispatch(LiveActions.checkAlerts())
                                                    dispatch(LiveActions.checkFilters())
                                                }}
                                                value={item.distanza}
                                                style={{ width: '40%' }}
                                            >
                                            </FormControl>
                                        </FormGroup>
                                    </div> :
                                    <></>
                            )
                        })
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* CAMPIONATI */}
            <Modal
                show={openPopupCampionati}
                onHide={() => setOpenPopupCampionati(!openPopupCampionati)}
                scrollable='true'>
                <Modal.Header onHide={() => setOpenPopupCampionati(!openPopupCampionati)} closeButton>
                    <h5 className="modal-title">Campionati</h5>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ fontSize: '13px', textAlign: "justify", paddingBottom: "16px" }}>
                        Selezionare il campionato da visualizzare.
                    </div>
                    <p style={{ width: "100%", height: "2px", background: "#dee2e6", position: "absolute", left: 0 }}></p>
                    <div style={{ paddingTop: "16px" }}>
                        {Object.keys(listaCampionati).length > 0 && Object.keys(listaCampionati).map((campionato_id) => {
                            return (
                                <div key={campionato_id} className='popup-checkbox'>
                                    {
                                        /* 
                                            Faccio  il parsing INT poichè nella visualizzazione me li ritorna come stringhe 
                                            ma negli eventi sono degli interi quindi nello stato dei campionati non visualizzati li inserisco
                                            come interi
                                        */
                                    }
                                    <FormCheck
                                        checked={!campionati_non_visualizzati.includes(parseInt(campionato_id))}
                                        label={listaCampionati[campionato_id]}
                                        onChange={() => {
                                            dispatch(LiveActions.setCampionatoNonVisibile(parseInt(campionato_id)))
                                            dispatch(LiveActions.checkFilters())
                                        }} />
                                </div>
                            )
                        })
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* CONCESSIONARI */}
            <Modal
                show={openPopupSpecialita}
                onHide={() => setOpenPopupSpecialita(!openPopupSpecialita)}
                size='sm'
                scrollable='true'>
                <Modal.Header onHide={() => setOpenPopupSpecialita(!openPopupSpecialita)} closeButton>
                    <h5 className="modal-title">Specialita</h5>
                </Modal.Header>
                <Modal.Body >
                    <div style={{ fontSize: '13px', textAlign: "justify", paddingBottom: "16px" }}>
                        Selezionare la specialita da visualizzare.
                    </div>
                    <p style={{ width: "100%", height: "2px", background: "#dee2e6", position: "absolute", left: 0 }}></p>
                    <div style={{ paddingTop: "16px" }}>
                        {specialita.map((spec, index) => {
                            return (
                                <div key={index} className='popup-checkbox'>
                                    <FormCheck
                                        checked={spec.visible}
                                        label={spec.descrizione}
                                        onChange={() => {
                                            dispatch(LiveActions.setSpecialitaVisible(spec.id, !spec.visible))
                                            dispatch(LiveActions.checkFilters())
                                        }} />
                                </div>
                            )
                        })
                        }
                    </div>
                </Modal.Body>
            </Modal>


            <nav className="Home-NavBar navbar navbar-dark bg-dark justify-content-center">
                <div className='container-fluid'>
                    <div className='d-flex'>
                        <InputGroup size='sm'>
                            <FormControl
                                id='form-ricerca'
                                type="text"
                                size='sm'
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        dispatch(LiveActions.setFiltroEvento(e.target.value))
                                        dispatch(LiveActions.checkFilters())
                                    }
                                }}
                                onChange={(e) => {
                                    setSearchEventoString(e.target.value)
                                }}
                                placeholder='Ricerca Team..'
                                value={search_evento_string}
                            />
                            <button
                                className='btn btn-secondary btn-sm'
                                onClick={() => {
                                    dispatch(LiveActions.setFiltroEvento(''))
                                    dispatch(LiveActions.checkFilters())
                                    // pulisco la form 
                                    setSearchEventoString('')
                                }}
                                title='Pulisci ricerca'
                            >
                                <FaDeleteLeft size={17} />
                            </button>
                        </InputGroup>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Campionati'
                            onClick={() => {
                                setListaCampionati(generateListaCampionati(eventi))
                                setOpenPopupCampionati(!openPopupCampionati)
                            }}
                        >
                            <TfiCup size={17} />
                        </button>

                    </div>
                    <div className='d-flex'>
                        <button
                            className={"mx-1 btn btn-sm" + (eye_state ? ' btn-danger' : ' btn-secondary')}
                            title={eye_state ? 'Tutti gli eventi' : 'Solo gli eventi con avvisi'}
                            onClick={() => {
                                dispatch(LiveActions.setEyeState(!eye_state))
                                dispatch(LiveActions.checkFilters())

                            }
                            }>
                            <FaEye size={17} />
                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Confronti'
                            onClick={() => {
                                setOpenPopupConfronti(!openPopupConfronti)
                            }}
                        >
                            <BiMath size={17} />
                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Abilita concessionari'
                            onClick={() => {
                                setOpenPopupConcessionari(!openPopupConcessionari)
                            }}
                        >
                            <FaGlobeAmericas size={17} />
                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Specialita'
                            onClick={() => {
                                setOpenPopupSpecialita(!openPopupSpecialita)
                            }}
                        >
                            <FaList size={17} />
                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Salva Impostazioni'
                            onClick={() => {
                                dispatch(LiveActions.salvaStato())
                                // messaggio di conferma
                                confirmAlert({
                                    title: '',
                                    message: 'Impostazioni salvate con successo!',
                                    buttons: [
                                        {
                                            label: 'Ok',
                                        }
                                    ]
                                }
                                );
                            }}
                        >
                            <FaRegSave size={17} />

                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Suona Alert'
                            onClick={() => {
                                dispatch(LiveActions.setAlertSound(!alert_sound))
                            }}
                        >
                            {alert_sound ? <FaRegBell size={17} /> : <FaRegBellSlash size={17} />}
                        </button>
                        <button
                            className="mx-1 btn btn-secondary btn-sm"
                            title='Visualizza Alert Bar'
                            onClick={() => { //BOTTONE CHE MOSTRA O MENO LA BARRA DEGLI ALERT 
                                setRighMenuShow(!rightMenuShow)
                            }}>
                            {rightMenuShow ? <IoIosWarning color='#fff700' size={20} /> : <IoIosWarning size={20} />}
                        </button>
                    </div>
                    <div className='d-flex' style={{ width: '200px' }}>
                        <div className='container text-light' style={{ fontSize: "8px", userSelect: "none" }}>
                            <div className='row'>
                                Versione: {LiveConst.VERSIONE}
                            </div>
                            <div className='row'>
                                Numero Eventi: {numEventi}
                            </div>
                            <div className='row'>
                                Aggiornato alle: {ultimo_aggiornamento}
                            </div>
                        </div>
                        <span className='mx-1 mt-1'>
                            {socketConnected ? <MdOutlineWifi title='Connessione al server stabilita' style={{ color: "green" }} size={28} /> : <MdOutlineWifiOff title='Connessione al server in corso...' style={{ color: "red" }} size={28} />}
                        </span>
                        <button
                            className="mx-2 btn btn-secondary btn-sm"
                            title='Aggiorna'
                            onClick={() => {
                                confirmAlert({
                                    title: '',
                                    message: 'Sicuro di voler ricaricare la pagina?',
                                    buttons: [
                                        {
                                            label: 'Si',
                                            onClick: () => window.location.reload(false)
                                        },
                                        {
                                            label: 'No',
                                        }
                                    ]
                                }
                                );
                            }}
                        >
                            <IoReload size={17} />
                        </button>
                    </div>
                </div>
            </nav>

            <ScrollSync>
                {({
                    clientHeight,
                    clientWidth,
                    onScroll,
                    scrollHeight,
                    scrollLeft,
                    scrollTop,
                    scrollWidth,
                }) => {
                    const x = scrollLeft / (scrollWidth - clientWidth);
                    const y = scrollTop / (scrollHeight - clientHeight);

                    const leftBackgroundColor = '#666';
                    const leftColor = 'grey';
                    const topBackgroundColor = '#666'
                    const topColor = 'white';
                    const middleBackgroundColor = 'grey'
                    const middleColor = 'black';
                    const bodyBackgroundColor = 'white';
                    const rightBackgroundColor = "#666";

                    return (
                        <div className="GridRow">
                            <div
                                className="LeftSideGridContainer"
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 1,
                                    color: leftColor,
                                    backgroundColor: topBackgroundColor,
                                }}>
                                <Grid
                                    cellRenderer={_renderLeftHeaderCell}
                                    key={'left-header'}
                                    className="HeaderGrid"
                                    width={columnWidth}
                                    height={rowHeaderHeight}
                                    rowHeight={rowHeaderHeight}
                                    columnWidth={columnWidth}
                                    rowCount={1}
                                    columnCount={1}
                                />
                            </div>
                            <div
                                className="LeftSideGridContainer"
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: rowHeaderHeight + 1,
                                    color: topColor,
                                    backgroundColor: leftBackgroundColor,
                                }}>
                                <Grid
                                    overscanColumnCount={overscanColumnCount}
                                    overscanRowCount={overscanRowCount}
                                    key={'left-body'}
                                    cellRenderer={_renderLeftSideCell}
                                    columnWidth={columnWidth}
                                    columnCount={1}
                                    className="LeftSideGrid"
                                    height={height}
                                    rowHeight={rowHeight}
                                    rowCount={rowCount}
                                    scrollTop={scrollTop}
                                    width={columnWidth}
                                />
                            </div>
                            <div className="GridColumn" style={{ backgroundColor: 'grey' }}>
                                <AutoSizer disableHeight>
                                    {({ width }) => (
                                        <div>
                                            <div
                                                style={{
                                                    color: topColor,
                                                    marginLeft: columnWidth - columnHeaderWidth,
                                                    backgroundColor: 'red',
                                                }}>
                                                <Grid
                                                    className="HeaderGrid"
                                                    columnWidth={_getColumnWidth}
                                                    key={'header'}
                                                    rowCount={1}
                                                    columnCount={columnCount}
                                                    height={rowHeaderHeight}
                                                    overscanColumnCount={overscanColumnCount}
                                                    cellRenderer={_renderHeaderCell}
                                                    rowHeight={rowHeaderHeight}
                                                    scrollLeft={scrollLeft}
                                                    width={width + columnHeaderWidth - columnWidth - scrollbarSize()}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    backgroundColor: bodyBackgroundColor,
                                                    color: middleColor,
                                                    marginLeft: columnWidth - columnHeaderWidth,
                                                }}>
                                                <Grid
                                                    className="BodyGrid"
                                                    key={'body'}
                                                    columnWidth={_getColumnWidth}
                                                    columnCount={columnCount}
                                                    height={height}
                                                    onScroll={onScroll}
                                                    overscanColumnCount={overscanColumnCount}
                                                    overscanRowCount={overscanRowCount}
                                                    cellRenderer={_renderBodyCell}
                                                    rowHeight={rowHeight}
                                                    rowCount={rowCount}
                                                    width={width + columnHeaderWidth - columnWidth}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </AutoSizer>
                            </div>
                            {// BARRA CONTENENTE GLI ALERT SCATTATI, visualizzabile solo tramite click del bottone
                                !rightMenuShow ? '' :
                                    <div id='RightSideGridContainer' className='RightSideGridContainer border-start border-dark' style={{
                                        backgroundColor: rightBackgroundColor,
                                    }}>
                                        <Container>
                                            <Row className="AlertRow justify-content-center">
                                                {// Recupero le informazioni dagli stati globali tramite gli id contenuti in info, passandoli al componente
                                                    alertsBar.map((alertInfo => {
                                                        return <Alert alertInfo={alertInfo} key={alertInfo.id} eventi={eventi} concessionari={concessionari} elencoAssociazioniEventi={elencoAssociazioniEventi} specialita={specialita} quote={quote} psport_id={psport_id} />;
                                                    }))}
                                            </Row>
                                            <Row className="AlertRow-Settings align-items-center" style={{cursor: 'pointer'}} onClick={() => {
                                                        // Apertura del popup delle impostazioni inerenti all'alert bart
                                                        setOpenPopupAlertBarImpostazioni(!openPopupAlertBarImpostazioni);
                                                    }}>
                                                <Col className='col-5 d-flex justify-content-end pe-0' >
                                                    <IoSettingsOutline size={16}/>
                                                </Col>
                                                <Col className='p-1'>
                                                    <div style={{
                                                        fontSize: "12px",
                                                        fontWeight: 500,
                                                    }}>Impostazioni</div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                            }
                        </div>
                    );
                }}
            </ScrollSync>

        </>
    );



    function _getColumnWidth({ index }) {
        if (index === 0) {
            return columnHeaderWidth;
        } else if (index <= getSpecialitaVisibili().length) {
            // condizione necessaria dato che questo componenente viene renderizzato prima di settare il numero effettivo di colonne (Vedi UseEffect sulle specialita)
            return columnHeaderWidth * getSpecialitaVisibili()[index - 1].esiti.length;
        } else {
            // dovrò comunque ritornare la larghezza della colonna anche se non ci sono specialità
            return 0;
        }
    };

    function _renderLeftSideCell({ columnIndex, key, rowIndex, style }) {
        let eventiVisibili = getEventiVisibili()
        let eventiKeys = reorderEventi(eventiVisibili, eye_state);
        let specialitaVisibili = getSpecialitaVisibili()
        return (
            <div style={style} key={key}>
                {columnIndex === 0 ?
                    <CellaEvento
                        evento={(eventiKeys ? eventiVisibili[eventiKeys[rowIndex]] : undefined)}
                        concessionari={concessionari}
                        chiave={key}
                        style={style}
                        removeEvento={_removeEvento}
                        psport_id={psport_id}
                        elencoAssociazioniEventi={elencoAssociazioniEventi}
                    />
                    :
                    <CellaQuote
                        evento={eventiVisibili[eventiKeys[rowIndex]]}
                        concessionari={concessionari}
                        specialita={specialitaVisibili[columnIndex - 1]}
                        quote={quote[eventiKeys[rowIndex]]}
                        alertConfronti={alertConfronti}
                    />
                }
            </div>
        );
    }

    function _renderLeftHeaderCell({ columnIndex, key, rowIndex, style }) {

        // if (columnIndex < 1) {
        //   return(
        //   <div key={key} style={{...style, backgroundColor: 'grey', borderRight: '1px solid #333'}}>
        //     {``}
        //   </div>  
        //   )  
        // }
        let specialitaVisibili = getSpecialitaVisibili()
        return (
            <div key={key} style={{ ...style }}>

                {specialitaVisibili.length !== 0 &&
                    columnIndex <= specialitaVisibili.length && // condizione necessaria dato che questo componenente viene renderizzato prima di settare il numero effettivo di colonne (Vedi UseEffect sulle specialita)  
                    <CellaIntestazioneSpecialita
                        style={style}
                        chiavi={key}
                        specialita={specialitaVisibili.length > 0 ? specialitaVisibili[columnIndex - 1] : {}}
                        primaCella={columnIndex < 1} // parametro che indica se stiamo renderizzando la prima cella della riga
                    />
                }
            </div>
        );
    }

    function _renderHeaderCell({ columnIndex, key, rowIndex, style }) {
        if (columnIndex < 1) {
            return;
        }

        return _renderLeftHeaderCell({ columnIndex, key, rowIndex, style });
    }

    function _renderBodyCell({ columnIndex, key, rowIndex, style }) {
        if (columnIndex < 1) {
            return;
        }

        return _renderLeftSideCell({ columnIndex, key, rowIndex, style });
    }

    /**
     * Rimuove l'evento indicato dal visualizzatore e ignora i futuri aggiornamenti relativi a questo evento
     * @param {int} pevento_id Identificativo dell'evento da rimuovere
     */
    function _removeEvento(pevento_id) {
        confirmAlert({
            title: '',
            message: 'Sicuro di voler rimuovere l\'evento?',
            buttons: [
                {
                    label: 'Si',
                    onClick: () => dispatch(LiveActions.removeEvento(pevento_id))
                },
                {
                    label: 'No',
                }
            ]
        }
        );
    }

}


export default Live;