import { LiveConst } from "../../../constants/live";
import * as LiveActions from '../../../redux/live/actions';
import { confirmAlert } from 'react-confirm-alert';
import { AiOutlineDelete } from "react-icons/ai";




const CellaEventoTennis = (props) => {


    let heightEvento = props.style.height - 1;
    

    return(
        <>
        {props && props.evento &&
            <div className="col overflow-hidden cellaevento">
                <div className="row">
                    <div className="col-1 p-1 pt-3" style={{backgroundColor: '#999', cursor: 'pointer', borderRight: '1px solid gray'}} title='Rimuovi Evento' onClick={()=>{props.removeEvento(props.evento.pevento_id)}}>
                        <AiOutlineDelete size={12} color="black" />
                    </div>
                    <div style={{backgroundColor: 'lightgray', color: 'black'}} className="col text-nowrap overflow-hidden p-0">
                        <div className="col-7 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.home}</div>
                        <div className="col-7 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.away}</div>
                    </div>
                    <div className="col-auto">
                        <div className="row p-0 text-center">
                            {props.evento.info_live && props.evento.info_live.totalSets &&
                                props.evento.info_live.totalSets.map((set, index) =>
                                    <div style={{backgroundColor: 'black', minWidth: '24px'}} className="col-auto p-0 text-center">
                                        <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{set && set.home}</div>
                                        <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{set && set.away}</div>    
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div style={{backgroundColor: 'green', 'width': '25px'}} className="col-2 p-0 text-center">
                            <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.info_live && props.evento.info_live.currentGame.home}</div>
                            <div className="col-12 p-1" style={{height:LiveConst.ALTEZZA_RIGA_CONCESSIONARIO}}>{props.evento.info_live && props.evento.info_live.currentGame.away}</div>
                    </div>
                </div>
                <div className="row">
                    <div style={{color: 'darkgray'}}  className="col-12 p-1 text-center">
                        {props.evento.descrizione}
                    </div>
                    {/* {props.evento.data_attivazione_alert &&                     
                        <div style={{color: 'darkgray'}}  className="col-12 p-1 text-center">
                            Alert Attivato alle: {props.evento.data_attivazione_alert.toLocaleTimeString('it-IT', {hour: '2-digit', minute:'2-digit', second: '2-digit'})}    
                        </div>

                    } */}

                </div>
            </div>
        }</>
    );
}
export default CellaEventoTennis;