import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <h1>HI</h1>
  );
}

export default App;
