import { LiveConst } from "../../constants/live";

const CellaIntestazioneSpecialita = (props) => {
    return(
        <>
        {props &&
        <>
            {props.primaCella? // questo parametro indica se stiamo renderizzando la prima cella della riga di intestazione
                <div key={props.chiavi} style={{...props.style, backgroundColor: 'grey', borderRight: '1px solid #333'}}>
                    {``}
                </div>:
                <div  key={props.chiavi} style={{userSelect: "none"}}>
                    <div 
                        className="col text-center text-nowrap overflow-hidden p-1 intestazione-specialita" 
                    >
                    {props.specialita.descrizione}
                    </div>
                    <div className="row m-0">
                    {props.specialita.esiti.map((esito, index) => (
                        <div
                        key = {String(index) + String(props.keys)} 
                        className="text-center text-nowrap overflow-hidden intestazione-esito p-1"
                        style={{width: LiveConst.COLUMN_HEADER_WIDTH}}
                        >
                            {esito.descrizione}
                        </div>
                    ))}
                    </div>
                </div>
            }
        </>
        }
        </>
        
    );
}
export default CellaIntestazioneSpecialita;